import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
// import { Image } from 'cloudinary-react'

import s from './styles.module.css'

// const imageProps = {
//   cloudName: 'chalk-store',
//   type: 'fetch',
//   fetchFormat: 'auto',
//   loading: 'lazy',
//   dpr: 'auto',
//   width: 'auto',
//   quality: '100',
//   responsive: true,
//   secure: true,
// }

const Instagram = ({ className }) => {
  const [posts, setPosts] = useState([])
  useEffect(() => {
    fetch(
      `https://tuceripksl.execute-api.eu-west-2.amazonaws.com/default/Instagram`
    )
      .then((res) => res.json())
      .then((data) => {
        data instanceof Array
          ? setPosts(data)
          : console.log('Fetch error:', data)
      })
  }, [])

  if (posts?.length < 1) return null

  return (
    <div className={classNames(s.container, className)}>
      <div className={s.posts}>
        {posts.map(({ id, type, thumbnail, video_thumbnail }) => {
          if (!thumbnail && !video_thumbnail) return null
          const setSrc =
            type === 'VIDEO' && video_thumbnail ? video_thumbnail : thumbnail
          return (
            <div key={id} className={s.post}>
              {/* <Image className={s.img} publicId={setSrc} {...imageProps} /> */}
              <img
                src={`https://images.weserv.nl/?url=${encodeURIComponent(
                  setSrc
                )}&w=300`}
                alt=""
                className={s.img}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

Instagram.propTypes = {
  className: PropTypes.string,
}
Instagram.defaultProps = {
  className: '',
}

export default Instagram
