/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import classNames from 'classnames'

import Layout from 'components/Layout'
import SEO from 'components/SEO'
// import ProductSlider from 'components/ProductSlider'
import TextImage from 'components/TextImage'
import Promo from 'components/Promo'
import Instagram from 'components/Instagram'
import LinkWordPress from 'components/LinkWordPress'

import s from './index.module.css'

const Hero = ({
  title,
  description,
  images,
  link,
  linkType,
  linkLabel,
  productLinkType,
  collectionHandle,
  productHandle,
  className = '',
}) => {
  return (
    <div className={classNames(s.hero, className)}>
      <LinkWordPress
        className={s.link}
        linkType={linkType}
        link={link}
        productLinkType={productLinkType}
        collectionHandle={collectionHandle}
        productHandle={productHandle}
        linkLabel={linkLabel}
      />
      {images?.[0]?.mobile?.localFile && (
        <div className={classNames(s.img, s.imgMobile)}>
          <GatsbyImage
            image={getImage(images[0].mobile.localFile)}
            loading="eager"
            alt="gallery"
          />
        </div>
      )}
      {images?.[0]?.desktop?.localFile && (
        <div className={classNames(s.img, s.imgDesktop)}>
          <GatsbyImage
            image={getImage(images[0].desktop.localFile)}
            loading="eager"
            alt="gallery"
          />
        </div>
      )}
      <div className={s.detailsContainer}>
        <div className={s.details}>
          {title && (
            <h1
              className={s.title}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          )}
          {description && (
            <div
              className={s.description}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
          {(link?.title || linkLabel) && (
            <span className={s.linkLabel}>
              {linkType === 'product' ? linkLabel : link?.title}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

const Home = ({ data }) => {
  const {
    seo: { title: seoTitle, description: seoDescription },
    acfHomepage: {
      homepageHeader,
      homepageFooter,
      homepageIntroduction,
      // homepageProductGallerySlider,
      homepageTextImage,
      homepagePromo,
    },
  } = data.wordpressPage
  return (
    <Layout>
      <Helmet>
        <html data-theme-color="ebb" />
      </Helmet>
      <SEO title={seoTitle} description={seoDescription} />
      <Hero {...homepageHeader} />
      {homepageIntroduction?.text && (
        <div className={s.introduction}>
          <div className={s.container}>
            <div className={s.text}>
              <div
                className={s.description}
                dangerouslySetInnerHTML={{
                  __html: homepageIntroduction.text,
                }}
              />
              {homepageIntroduction?.links?.length > 0 && (
                <div className={s.links}>
                  {homepageIntroduction.links.map((link) => (
                    <LinkWordPress
                      key={link?.url}
                      className={s.link}
                      {...link}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className={s.productsWrapper}>
        <TextImage className={s.textImage} data={homepageTextImage} />
        {/* <ProductSlider
          className={s.productSlider}
          data={homepageProductGallerySlider}
        /> */}
      </div>
      <Promo data={homepagePromo} />
      <Hero className={s.footerHero} {...homepageFooter} />
      <Instagram />
    </Layout>
  )
}

Home.propTypes = {
  data: PropTypes.shape({
    wordpressPage: PropTypes.shape({
      seo: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
      }),
      acfHomepage: PropTypes.shape({
        homepageHeader: PropTypes.object,
        homepageFooter: PropTypes.object,
        homepageIntroduction: PropTypes.object,
        homepageProductGallerySlider: PropTypes.object,
        homepageTextImage: PropTypes.array,
        homepagePromo: PropTypes.object,
      }),
    }),
    allShopifyCollection: PropTypes.shape({
      nodes: PropTypes.array,
    }),
  }),
}

export default Home

export const pageQuery = graphql`
  query Homepage {
    wordpressPage(uri: { eq: "/" }) {
      seo {
        title
        description
      }
      acfHomepage {
        homepageHeader {
          title
          description
          linkType
          link {
            target
            title
            url
          }
          productLinkType
          collectionHandle
          productHandle
          linkLabel
          images {
            mobile {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 900, placeholder: NONE)
                }
              }
            }
            desktop {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2560, placeholder: NONE)
                }
              }
            }
          }
        }
        homepageFooter {
          title
          description
          linkType
          link {
            target
            title
            url
          }
          productLinkType
          collectionHandle
          productHandle
          linkLabel
          images {
            mobile {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 900, placeholder: NONE)
                }
              }
            }
            desktop {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2560, placeholder: NONE)
                }
              }
            }
          }
        }
        homepageIntroduction {
          text
          links {
            linkType
            link {
              target
              title
              url
            }
            productLinkType
          }
        }
        homepageProductGallerySlider {
          title
          slider {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 275
                    placeholder: NONE
                    aspectRatio: 0.75
                  )
                }
              }
            }
            linkType
            collectionHandle
            productHandle
            linkLabel
          }
        }
        homepageTextImage {
          title
          description
          linkType
          link {
            target
            title
            url
          }
          productLinkType
          collectionHandle
          productHandle
          linkLabel
          images {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 800, placeholder: NONE)
                }
              }
            }
          }
        }
        homepagePromo {
          images {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 800, placeholder: NONE, aspectRatio: 1)
                }
              }
            }
          }
          title
          description
          linkType
          link {
            target
            title
            url
          }
          productLinkType
          collectionHandle
          productHandle
          linkLabel
        }
      }
    }
  }
`
