import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import SliderImages from 'components/SliderImages'
import LinkWordPress from 'components/LinkWordPress'

import s from './styles.module.css'

const Promo = ({ data, className }) => {
  const {
    images,
    title,
    // description,
    linkType,
    link,
    productLinkType,
    collectionHandle,
    productHandle,
    linkLabel,
  } = data
  return (
    <div className={classNames(s.container, className)}>
      <div className={s.promo}>
        <SliderImages images={images} className={s.images} aspectRatio="1:1" />
        <div className={s.detailsWrapper}>
          <div className={s.spacing} />
          <div className={s.details}>
            {title && (
              <h2
                className={s.title}
                dangerouslySetInnerHTML={{ __html: title }}
              />
            )}
            <LinkWordPress
              className={s.link}
              linkType={linkType}
              link={link}
              productLinkType={productLinkType}
              collectionHandle={collectionHandle}
              productHandle={productHandle}
              linkLabel={linkLabel}
              fullyClickable
            />
          </div>
        </div>
      </div>
    </div>
  )
}

Promo.propTypes = {
  data: PropTypes.shape({
    images: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.object,
      })
    ),
    title: PropTypes.string,
    description: PropTypes.string,
    linkType: PropTypes.string,
    link: PropTypes.shape({
      target: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
    }),
    productLinkType: PropTypes.string,
    collectionHandle: PropTypes.string,
    productHandle: PropTypes.string,
    linkLabel: PropTypes.string,
  }),
  className: PropTypes.string,
}

Promo.defaultProps = {
  data: {},
  className: '',
}

export default Promo
